@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@tailwind base;

h1 {
  @apply text-4xl font-bold mt-6 mb-6;
}

h2 {
  @apply text-3xl font-bold mt-6 mb-6;
}

h3 {
  @apply text-2xl font-bold mt-6 mb-6;
}

h4 {
  @apply text-xl font-bold mt-6 mb-6;
}

h5 {
  @apply text-lg font-bold mt-6 mb-6;
}

@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Natural Signature';
  font-weight: 400;
  src: url('/assets/fonts/NaturalSignature.otf');
}

@font-face {
  font-family: 'Fromage';
  font-weight: 'light';
  src: url('/assets/fonts/Fromage\ Light.ttf');
}

@font-face {
  font-family: 'Purista';
  font-weight: 400;
  src: url('/assets/fonts/Purista.ttf');
}

@font-face {
  font-family: 'Purista';
  font-weight: 'semibold';
  src: url('/assets/fonts/Purista\ SemiBold.ttf');
}

.common-button {
  @apply hover:opacity-50 disabled:opacity-60 disabled:cursor-not-allowed
}

main {
  @apply min-h-screen relative pt-navigationMobile md:pt-navigation
}

header {
  @apply fixed top-0 left-0 right-0 h-navigationMobile md:h-navigation flex justify-between items-center bg-white px-[30px] md:pl-[48px] py-[28px] md:pr-[50px]
}

header .nav-span {
  @apply font-montserrat text-[20px] md:space-x-[67px] lg:space-x-[90px] hidden md:flex items-center
}

header .nav-span a {
  @apply md:px-[10px] lg:px-[25px] py-2 cursor-pointer uppercase
}

header .nav-hamburger {
  @apply md:hidden
}

.navigation-panel {
  @apply md:hidden bg-white text-black flex flex-col space-y-[58px] fixed top-navigationMobile left-0 right-0 py-[70px] z-hamburger
}

.navigation-panel button {
  @apply w-full block py-[1px] font-montserrat uppercase text-[20px] mx-auto
}

footer {
  @apply w-full pt-[64px] pb-[73px] md:!py-[74px] px-[30px] md:px-[51px] bg-white min-h-[theme(space.footer)]
}

footer .top-left-mobile button {
  @apply block font-montserrat uppercase text-[20px] md:text-[20px] text-left
}

footer .top-right-mobile {
  @apply flex flex-col justify-between items-end
}

footer .ins-mobile {
  @apply -mx-[30px] md:-mx-[51px] grid grid-cols-3 md:grid-cols-6 max-w-[100vw] !h-[66vw] md:!h-[16.67vw] overflow-x-hidden
}

footer .ins-mobile button {
  @apply w-full !h-[33vw] md:!h-[16.67vw]
}

footer .ins-mobile img {
  @apply !w-full !h-[33vw] md:!h-[16.67vw] object-cover
}

footer .ins-mobile img {
  @apply w-[100px] h-[100px]
}

.accent-word-underline {
  @apply w-fit border-b-[2px] md:border-b-[5px] pb-[2px] border-[#DEC089]
}

.window-frame-height {
  @apply mt-[calc(100lvh-theme(space.navigationMobile))] md:mt-[100lvh]
}

.boundary {
  @apply md:max-w-[1700px] md:mx-auto
}

.home {
  @apply relative bg-transparent window-frame-height
}

.home-card {
  @apply px-[30px] md:px-[100px] lg:px-[207px] bg-white
}

.home .about {
  @apply relative space-y-[50px] md:space-y-[200px] pt-[150px] md:pt-[229px] pb-[150px] md:pb-[345px] z-[1] home-card
}

.home .about .about-item {
  @apply flex flex-col md:flex-row md:!items-center md:justify-start space-y-[35px] md:space-y-0 md:space-x-[88px] lg:space-x-[156px] w-full boundary
}

.home .about .about-item div.about-item-heading {
  @apply w-fit font-fromage font-light uppercase text-[24px] md:text-[36px] accent-word-underline mb-[35px] md:mb-[50px]
}

.home .about .about-item p, .feature-item .description p {
  @apply font-montserrat text-[12px] md:text-[16px] leading-[32px]
}

.home .about .about-item img.landscape {
  @apply w-[280px] md:max-w-[880px] h-[186px] md:h-[556px] object-cover flex-1 mr-0 md:mr-auto
}

.home .about .about-item img.portrait {
  @apply w-[229px] md:max-w-[880px] h-[344px] md:h-[556px] md:flex-1 object-cover ml-0 mt-[50px] md:mt-auto
}

.home .features {
  @apply relative pt-[143px] md:pt-[336px] z-[1] home-card
}

.home .features .heading {
  @apply mx-auto w-fit font-fromage font-light uppercase text-[24px] md:text-[36px] mb-[55px] md:mb-[80px] accent-word-underline
}

.home .features .feature-items {
  @apply flex flex-col md:flex-row space-x-[11px] md:space-x-[30px] lg:space-x-[48px] justify-center mx-auto boundary
}

.home .features .feature-items div.feature-item {
  @apply flex-1 relative
}

.home .features .feature-items img {
  @apply aspect-square /*h-[173px] md:h-[637px]*/ w-full mb-[25px] md:mb-[100px] object-cover
}

.home .features .feature-items .title {
  @apply font-montserrat text-[12px] md:text-[32px] text-center
}

.home .menu {
  @apply relative home-card pb-[150px] md:pb-[352px] pt-[134px] md:pt-[288px]
}

.home .menu .heading {
  @apply mx-auto w-fit font-fromage font-light uppercase text-[24px] md:text-[42px] md:mb-[155px] accent-word-underline
}

.home .menu .layout-mobile {
  @apply flex flex-col items-center md:hidden
}

.home .menu .layout {
  @apply hidden md:flex md:space-x-6 justify-around boundary
}


.home .menu .menu-section {
  @apply space-y-[30px] md:space-y-[60px] py-[31px] md:py-[53] px-[38px] md:px-[60px] w-[368px] md:w-[530px]
}

.home .menu .menu-section.with-border {
  @apply border border-black
}

.home .menu .menu-section .menu-section-heading {
  @apply font-purista tracking-widest uppercase text-[20px] md:text-[36px] text-center w-full
}

.home .menu .menu-items {
  @apply space-y-[16px] md:space-y-[23px]
}

.home .menu .menu-item {
  @apply flex justify-between items-center font-montserrat text-[16px] md:text-[20px]
}

.home .menu .menu-comments {
  @apply !mt-[30px] md:!mt-[46px] font-montserrat text-[14px] md:text-[20px] !space-y-[8px] md:!space-y-[10px] whitespace-pre-wrap
}

.home .career,
.home .contact-locations {
  @apply relative home-card pt-[100px] md:pt-[300px] pb-[200px] md:pb-[500px] window-frame-height
}

.home .career-items,
.home .contact-locations-items {
  @apply space-y-[58px] md:space-y-[200px]
}

.home .career-item {
  @apply grid md:grid-cols-3 gap-[35px] md:gap-[88px] lg:gap-[156px] items-center boundary
}

.home .career-item .title {
  @apply font-fromage font-light uppercase text-[36px] accent-word-underline !border-b-[5px] mb-[36px] md:mb-[55px]
}

.home .career-item .description {
  @apply font-montserrat text-[16px] whitespace-pre-wrap mb-[25px] md:mb-[43px] md:max-w-[400px] lg:max-w-[470px]
}

.home .career-item a {
  @apply ml-auto mr-0 md:ml-0 md:mr-auto w-fit block font-montserrat text-[16px] border border-black rounded-full px-[25px] py-[8px]
}

.home .contact-locations-item {
  @apply grid md:grid-cols-3 gap-[35px] md:gap-[68px] lg:gap-[216px] items-center boundary
}

.home .contact-locations-item .title {
  @apply font-fromage font-light uppercase accent-word-underline !border-b-[5px] mb-[36px] md:mb-[55px]
}

.home .contact-locations-item .title {
  font-size: clamp(32px, 8vw, 36px);
}

.home .contact-locations-item .description {
  @apply font-montserrat text-[12px] md:text-[16px] whitespace-pre-wrap mb-[25px] md:mb-[43px]
}

.home .career-items img,
.home .contact-locations-items img {
  @apply object-cover col-span-2 w-full max-w-[880px] h-[232px] md:h-[556px] md:mr-0 md:ml-auto
}
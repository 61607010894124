@import "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

h1 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

h2 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

h3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

h4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

h5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.-left-\[35px\] {
  left: -35px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[31px\] {
  bottom: 31px;
}

.left-0 {
  left: 0;
}

.left-\[11px\] {
  left: 11px;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-\[11px\] {
  right: 11px;
}

.right-\[24px\] {
  right: 24px;
}

.right-\[31px\] {
  right: 31px;
}

.top-0 {
  top: 0;
}

.top-\[24px\] {
  top: 24px;
}

.top-\[50\%\] {
  top: 50%;
}

.top-\[calc\(\(100lvh-theme\(space\.navigationMobile\)\)\/2\)\] {
  top: calc(50lvh - 50px);
}

.top-navigationMobile {
  top: 100px;
}

.z-\[-1001\] {
  z-index: -1001;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[1001\] {
  z-index: 1001;
}

.z-footer {
  z-index: 998;
}

.z-header {
  z-index: 999;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.\!-mt-\[15px\] {
  margin-top: -15px !important;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-\[24px\] {
  margin-bottom: 24px;
}

.mb-\[25px\] {
  margin-bottom: 25px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[40px\] {
  margin-bottom: 40px;
}

.mb-\[50px\] {
  margin-bottom: 50px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.mb-\[98px\] {
  margin-bottom: 98px;
}

.ml-\[32px\] {
  margin-left: 32px;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-\[16px\] {
  margin-top: 16px;
}

.mt-\[24px\] {
  margin-top: 24px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.mt-\[72px\] {
  margin-top: 72px;
}

.mt-\[8px\] {
  margin-top: 8px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.\!h-\[56px\] {
  height: 56px !important;
}

.\!h-\[calc\(100vh-theme\(space\.navigationMobile\)\)\] {
  height: calc(100vh - 100px) !important;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[58px\] {
  height: 58px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.\!w-\[320px\] {
  width: 320px !important;
}

.w-\[106px\] {
  width: 106px;
}

.w-\[135px\] {
  width: 135px;
}

.w-\[146px\] {
  width: 146px;
}

.w-\[157px\] {
  width: 157px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[174px\] {
  width: 174px;
}

.w-\[45px\] {
  width: 45px;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[calc\(100vw\+70px\)\] {
  width: calc(100vw + 70px);
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[275px\] {
  min-width: 275px;
}

.min-w-\[calc\(100vw\+70px\)\] {
  min-width: calc(100vw + 70px);
}

.max-w-\[272px\] {
  max-width: 272px;
}

.max-w-\[409px\] {
  max-width: 409px;
}

.flex-1 {
  flex: 1;
}

.-translate-x-\[50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0 {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0\.5 {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[80px\] {
  --tw-translate-y: 80px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.\!flex-col-reverse {
  flex-direction: column-reverse !important;
}

.\!items-start {
  align-items: flex-start !important;
}

.\!items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-\[16px\] {
  gap: 16px;
}

.gap-\[20px\] {
  gap: 20px;
}

.gap-\[24px\] {
  gap: 24px;
}

.gap-\[8px\] {
  gap: 8px;
}

.space-x-\[24px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24px * var(--tw-space-x-reverse));
  margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-\[20px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20px * var(--tw-space-y-reverse));
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[\#C4C4C4\] {
  --tw-border-opacity: 1;
  border-color: rgb(196 196 196 / var(--tw-border-opacity));
}

.\!bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.\!p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: 2.5rem;
}

.p-\[16px\] {
  padding: 16px;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}

.px-\[60px\] {
  padding-left: 60px;
  padding-right: 60px;
}

.py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-\[57px\] {
  padding-top: 57px;
  padding-bottom: 57px;
}

.text-center {
  text-align: center;
}

.font-fromage {
  font-family: Fromage;
}

.font-montserrat {
  font-family: Montserrat;
}

.font-natural {
  font-family: Natural Signature;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[48px\] {
  font-size: 48px;
}

.text-\[64px\] {
  font-size: 64px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-\[14px\] {
  font-weight: 14px;
}

.font-\[20px\] {
  font-weight: 20px;
}

.font-\[300\], .font-light {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: Natural Signature;
  font-weight: 400;
  src: url("NaturalSignature.6bda0fca.otf");
}

@font-face {
  font-family: Fromage;
  font-weight: "light";
  src: url("Fromage Light.be8d92d8.ttf");
}

@font-face {
  font-family: Purista;
  font-weight: 400;
  src: url("Purista.7e0cfd85.ttf");
}

@font-face {
  font-family: Purista;
  font-weight: "semibold";
  src: url("Purista SemiBold.fb26bca8.ttf");
}

.common-button:hover {
  opacity: .5;
}

.common-button:disabled {
  cursor: not-allowed;
  opacity: .6;
}

main {
  min-height: 100vh;
  padding-top: 100px;
  position: relative;
}

@media (width >= 1024px) {
  main {
    padding-top: 120px;
  }
}

header {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 28px 30px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 1024px) {
  header {
    height: 120px;
    padding-left: 48px;
    padding-right: 50px;
  }
}

header .nav-span {
  align-items: center;
  font-family: Montserrat;
  font-size: 20px;
  display: none;
}

@media (width >= 1024px) {
  header .nav-span {
    display: flex;
  }

  header .nav-span > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(67px * var(--tw-space-x-reverse));
    margin-left: calc(67px * calc(1 - var(--tw-space-x-reverse)));
  }
}

@media (width >= 1600px) {
  header .nav-span > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(90px * var(--tw-space-x-reverse));
    margin-left: calc(90px * calc(1 - var(--tw-space-x-reverse)));
  }
}

header .nav-span a {
  cursor: pointer;
  text-transform: uppercase;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media (width >= 1024px) {
  header .nav-span a {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (width >= 1600px) {
  header .nav-span a {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (width >= 1024px) {
  header .nav-hamburger {
    display: none;
  }
}

.navigation-panel {
  z-index: 1000;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
}

.navigation-panel > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(58px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(58px * var(--tw-space-y-reverse));
}

.navigation-panel {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  padding-top: 70px;
  padding-bottom: 70px;
}

@media (width >= 1024px) {
  .navigation-panel {
    display: none;
  }
}

.navigation-panel button {
  text-transform: uppercase;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1px;
  padding-bottom: 1px;
  font-family: Montserrat;
  font-size: 20px;
  display: block;
}

footer {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 100%;
  min-height: 535px;
  padding: 64px 30px 73px;
}

@media (width >= 1024px) {
  footer {
    padding-left: 51px;
    padding-right: 51px;
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
}

footer .top-left-mobile button {
  text-align: left;
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 20px;
  display: block;
}

@media (width >= 1024px) {
  footer .top-left-mobile button {
    font-size: 20px;
  }
}

footer .top-right-mobile {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

footer .ins-mobile {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: 100vw;
  margin-left: -30px;
  margin-right: -30px;
  display: grid;
  overflow-x: hidden;
  height: 66vw !important;
}

@media (width >= 1024px) {
  footer .ins-mobile {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    margin-left: -51px;
    margin-right: -51px;
    height: 16.67vw !important;
  }
}

footer .ins-mobile button {
  width: 100%;
  height: 33vw !important;
}

@media (width >= 1024px) {
  footer .ins-mobile button {
    height: 16.67vw !important;
  }
}

footer .ins-mobile img {
  object-fit: cover;
  width: 100% !important;
  height: 33vw !important;
}

@media (width >= 1024px) {
  footer .ins-mobile img {
    height: 16.67vw !important;
  }
}

footer .ins-mobile img {
  width: 100px;
  height: 100px;
}

.accent-word-underline {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(222 192 137 / var(--tw-border-opacity));
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 2px;
}

@media (width >= 1024px) {
  .accent-word-underline {
    border-bottom-width: 5px;
  }
}

.window-frame-height {
  margin-top: calc(100lvh - 100px);
}

@media (width >= 1024px) {
  .window-frame-height {
    margin-top: 100lvh;
  }

  .boundary {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
  }
}

.home {
  background-color: #0000;
  margin-top: calc(100lvh - 100px);
  position: relative;
}

@media (width >= 1024px) {
  .home {
    margin-top: 100lvh;
  }
}

.home-card {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 30px;
  padding-right: 30px;
}

@media (width >= 1024px) {
  .home-card {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (width >= 1600px) {
  .home-card {
    padding-left: 207px;
    padding-right: 207px;
  }
}

.home .about {
  z-index: 1;
  position: relative;
}

.home .about > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(50px * var(--tw-space-y-reverse));
}

.home .about {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 150px 30px;
}

@media (width >= 1024px) {
  .home .about {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (width >= 1600px) {
  .home .about {
    padding-left: 207px;
    padding-right: 207px;
  }
}

@media (width >= 1024px) {
  .home .about > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(200px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(200px * var(--tw-space-y-reverse));
  }

  .home .about {
    padding-top: 229px;
    padding-bottom: 345px;
  }
}

.home .about .about-item {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.home .about .about-item > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(35px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(35px * var(--tw-space-y-reverse));
}

@media (width >= 1024px) {
  .home .about .about-item {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    align-items: center !important;
  }

  .home .about .about-item > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right: calc(88px * var(--tw-space-x-reverse));
    margin-left: calc(88px * calc(1 - var(--tw-space-x-reverse)));
  }
}

@media (width >= 1600px) {
  .home .about .about-item > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(156px * var(--tw-space-x-reverse));
    margin-left: calc(156px * calc(1 - var(--tw-space-x-reverse)));
  }
}

.home .about .about-item div.about-item-heading {
  text-transform: uppercase;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(222 192 137 / var(--tw-border-opacity));
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 35px;
  padding-bottom: 2px;
  font-family: Fromage;
  font-size: 24px;
  font-weight: 300;
}

@media (width >= 1024px) {
  .home .about .about-item div.about-item-heading {
    border-bottom-width: 5px;
    margin-bottom: 50px;
    font-size: 36px;
  }
}

.home .about .about-item p, .feature-item .description p {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 32px;
}

@media (width >= 1024px) {
  .home .about .about-item p, .feature-item .description p {
    font-size: 16px;
  }
}

.home .about .about-item img.landscape {
  object-fit: cover;
  flex: 1;
  width: 280px;
  height: 186px;
  margin-right: 0;
}

@media (width >= 1024px) {
  .home .about .about-item img.landscape {
    max-width: 880px;
    height: 556px;
    margin-right: auto;
  }
}

.home .about .about-item img.portrait {
  object-fit: cover;
  width: 229px;
  height: 344px;
  margin-top: 50px;
  margin-left: 0;
}

@media (width >= 1024px) {
  .home .about .about-item img.portrait {
    flex: 1;
    max-width: 880px;
    height: 556px;
    margin-top: auto;
  }
}

.home .features {
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 143px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
}

@media (width >= 1024px) {
  .home .features {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (width >= 1600px) {
  .home .features {
    padding-left: 207px;
    padding-right: 207px;
  }
}

@media (width >= 1024px) {
  .home .features {
    padding-top: 336px;
  }
}

.home .features .heading {
  text-transform: uppercase;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(222 192 137 / var(--tw-border-opacity));
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 55px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2px;
  font-family: Fromage;
  font-size: 24px;
  font-weight: 300;
}

@media (width >= 1024px) {
  .home .features .heading {
    border-bottom-width: 5px;
    margin-bottom: 80px;
    font-size: 36px;
  }
}

.home .features .feature-items {
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.home .features .feature-items > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11px * var(--tw-space-x-reverse));
  margin-left: calc(11px * calc(1 - var(--tw-space-x-reverse)));
}

@media (width >= 1024px) {
  .home .features .feature-items {
    flex-direction: row;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
  }

  .home .features .feature-items > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(30px * var(--tw-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--tw-space-x-reverse)));
  }
}

@media (width >= 1600px) {
  .home .features .feature-items > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(48px * var(--tw-space-x-reverse));
    margin-left: calc(48px * calc(1 - var(--tw-space-x-reverse)));
  }
}

.home .features .feature-items div.feature-item {
  flex: 1;
  position: relative;
}

.home .features .feature-items img {
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
  margin-bottom: 25px;
}

@media (width >= 1024px) {
  .home .features .feature-items img {
    margin-bottom: 100px;
  }
}

.home .features .feature-items .title {
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
}

@media (width >= 1024px) {
  .home .features .feature-items .title {
    font-size: 32px;
  }
}

.home .menu {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 134px 30px 150px;
  position: relative;
}

@media (width >= 1024px) {
  .home .menu {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (width >= 1600px) {
  .home .menu {
    padding-left: 207px;
    padding-right: 207px;
  }
}

@media (width >= 1024px) {
  .home .menu {
    padding-top: 288px;
    padding-bottom: 352px;
  }
}

.home .menu .heading {
  text-transform: uppercase;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(222 192 137 / var(--tw-border-opacity));
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2px;
  font-family: Fromage;
  font-size: 24px;
  font-weight: 300;
}

@media (width >= 1024px) {
  .home .menu .heading {
    border-bottom-width: 5px;
    margin-bottom: 155px;
    font-size: 42px;
  }
}

.home .menu .layout-mobile {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .home .menu .layout-mobile {
    display: none;
  }
}

.home .menu .layout {
  justify-content: space-around;
  display: none;
}

@media (width >= 1024px) {
  .home .menu .layout {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .home .menu .layout > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

.home .menu .menu-section {
  width: 368px;
}

.home .menu .menu-section > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(30px * var(--tw-space-y-reverse));
}

.home .menu .menu-section {
  padding: 31px 38px;
}

@media (width >= 1024px) {
  .home .menu .menu-section {
    width: 530px;
  }

  .home .menu .menu-section > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(60px * var(--tw-space-y-reverse));
  }

  .home .menu .menu-section {
    padding: 53px 60px;
  }
}

.home .menu .menu-section.with-border {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.home .menu .menu-section .menu-section-heading {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .1em;
  width: 100%;
  font-family: Purista;
  font-size: 20px;
}

@media (width >= 1024px) {
  .home .menu .menu-section .menu-section-heading {
    font-size: 36px;
  }
}

.home .menu .menu-items > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16px * var(--tw-space-y-reverse));
}

@media (width >= 1024px) {
  .home .menu .menu-items > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(23px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(23px * var(--tw-space-y-reverse));
  }
}

.home .menu .menu-item {
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  display: flex;
}

@media (width >= 1024px) {
  .home .menu .menu-item {
    font-size: 20px;
  }
}

.home .menu .menu-comments {
  margin-top: 30px !important;
}

.home .menu .menu-comments > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(8px * var(--tw-space-y-reverse)) !important;
}

.home .menu .menu-comments {
  white-space: pre-wrap;
  font-family: Montserrat;
  font-size: 14px;
}

@media (width >= 1024px) {
  .home .menu .menu-comments {
    margin-top: 46px !important;
  }

  .home .menu .menu-comments > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10px * var(--tw-space-y-reverse)) !important;
  }

  .home .menu .menu-comments {
    font-size: 20px;
  }
}

.home .career, .home .contact-locations {
  margin-top: calc(100lvh - 100px);
  padding-top: 100px;
  padding-bottom: 200px;
  position: relative;
}

@media (width >= 1024px) {
  .home .career, .home .contact-locations {
    margin-top: 100lvh;
  }
}

.home .career, .home .contact-locations {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 30px;
  padding-right: 30px;
}

@media (width >= 1024px) {
  .home .career, .home .contact-locations {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (width >= 1600px) {
  .home .career, .home .contact-locations {
    padding-left: 207px;
    padding-right: 207px;
  }
}

@media (width >= 1024px) {
  .home .career, .home .contact-locations {
    padding-top: 300px;
    padding-bottom: 500px;
  }
}

.home .career-items > :not([hidden]) ~ :not([hidden]), .home .contact-locations-items > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(58px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(58px * var(--tw-space-y-reverse));
}

@media (width >= 1024px) {
  .home .career-items > :not([hidden]) ~ :not([hidden]), .home .contact-locations-items > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(200px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(200px * var(--tw-space-y-reverse));
  }
}

.home .career-item {
  align-items: center;
  gap: 35px;
  display: grid;
}

@media (width >= 1024px) {
  .home .career-item {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 88px;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 1600px) {
  .home .career-item {
    gap: 156px;
  }
}

.home .career-item .title {
  text-transform: uppercase;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(222 192 137 / var(--tw-border-opacity));
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 36px;
  padding-bottom: 2px;
  font-family: Fromage;
  font-size: 36px;
  font-weight: 300;
}

@media (width >= 1024px) {
  .home .career-item .title {
    border-bottom-width: 5px;
    margin-bottom: 55px;
  }
}

.home .career-item .description {
  white-space: pre-wrap;
  margin-bottom: 25px;
  font-family: Montserrat;
  font-size: 16px;
}

@media (width >= 1024px) {
  .home .career-item .description {
    max-width: 400px;
    margin-bottom: 43px;
  }
}

@media (width >= 1600px) {
  .home .career-item .description {
    max-width: 470px;
  }
}

.home .career-item a {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  border-radius: 9999px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  padding: 8px 25px;
  font-family: Montserrat;
  font-size: 16px;
  display: block;
}

@media (width >= 1024px) {
  .home .career-item a {
    margin-left: 0;
    margin-right: auto;
  }
}

.home .contact-locations-item {
  align-items: center;
  gap: 35px;
  display: grid;
}

@media (width >= 1024px) {
  .home .contact-locations-item {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 68px;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width >= 1600px) {
  .home .contact-locations-item {
    gap: 216px;
  }
}

.home .contact-locations-item .title {
  text-transform: uppercase;
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(222 192 137 / var(--tw-border-opacity));
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 36px;
  padding-bottom: 2px;
  font-family: Fromage;
  font-weight: 300;
}

@media (width >= 1024px) {
  .home .contact-locations-item .title {
    border-bottom-width: 5px;
    margin-bottom: 55px;
  }
}

.home .contact-locations-item .title {
  font-size: clamp(32px, 8vw, 36px);
}

.home .contact-locations-item .description {
  white-space: pre-wrap;
  margin-bottom: 25px;
  font-family: Montserrat;
  font-size: 12px;
}

@media (width >= 1024px) {
  .home .contact-locations-item .description {
    margin-bottom: 43px;
    font-size: 16px;
  }
}

.home .career-items img, .home .contact-locations-items img {
  object-fit: cover;
  grid-column: span 2 / span 2;
  width: 100%;
  max-width: 880px;
  height: 232px;
}

@media (width >= 1024px) {
  .home .career-items img, .home .contact-locations-items img {
    height: 556px;
    margin-left: auto;
    margin-right: 0;
  }
}

.placeholder\:text-\[\#787878\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(120 120 120 / var(--tw-text-opacity));
}

@media (width >= 1024px) {
  .md\:bottom-\[50px\] {
    bottom: 50px;
  }

  .md\:left-\[52px\] {
    left: 52px;
  }

  .md\:right-\[50px\] {
    right: 50px;
  }

  .md\:right-\[52px\] {
    right: 52px;
  }

  .md\:top-\[calc\(\(100lvh-theme\(space\.navigation\)\)\/2\)\] {
    top: calc(50lvh - 60px);
  }

  .md\:top-navigation {
    top: 120px;
  }

  .md\:\!-mt-\[35px\] {
    margin-top: -35px !important;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-\[31px\] {
    margin-bottom: 31px;
  }

  .md\:mb-\[50px\] {
    margin-bottom: 50px;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:\!h-\[100vh\] {
    height: 100vh !important;
  }

  .md\:w-\[180px\] {
    width: 180px;
  }

  .md\:w-\[253px\] {
    width: 253px;
  }

  .md\:max-w-\[410px\] {
    max-width: 410px;
  }

  .md\:max-w-\[470px\] {
    max-width: 470px;
  }

  .md\:\!flex-row {
    flex-direction: row !important;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-\[83px\] {
    gap: 83px;
  }

  .md\:text-\[128px\] {
    font-size: 128px;
  }

  .md\:text-\[14px\] {
    font-size: 14px;
  }

  .md\:text-\[64px\] {
    font-size: 64px;
  }
}

@media (width >= 1600px) {
  .lg\:w-\[200px\] {
    width: 200px;
  }
}

/*# sourceMappingURL=index.50e5f880.css.map */
